$(function () {
    initNav();
    initEqualHeights();
    initSearch();
    initMagnific();
    initObjectFavorite();
    initMasonry();
    initFaq();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        initNav();
        initEqualHeights();
    }, 200));
});

function initNav() {
    if (window.innerWidth < 768) {
        $('#btn_nav').unbind().click(function () {
            $('#search_wrapper').slideUp(function () {
                $('#user_wrapper').slideUp(function () {
                    $('#nav_wrapper').slideToggle();
                });
            });
        });

        $('#nav_wrapper ul li').has('ul').each(function () {
            var li = $(this);
            li.children('a').unbind().click(function (e) {
                if (li.children('ul').is(':visible')) {
                    li.children('ul').slideUp();
                } else {
                    e.preventDefault();
                    li.children('ul').slideDown();
                }
            });
        });

        $('#btn_search').unbind().click(function () {
            $('#nav_wrapper').slideUp(function () {
                $('#user_wrapper').slideUp(function () {
                    $('#search_wrapper').slideToggle();
                });
            });
        });

        $('#btn_user').unbind().click(function () {
            $('#nav_wrapper').slideUp(function () {
                $('#search_wrapper').slideUp(function () {
                    $('#user_wrapper').slideToggle();
                });
            });
        });
    } else {
        // RESET
        $('header button').unbind();

        $('#nav_wrapper ul li').has('ul').each(function () {
            var li = $(this);
            li.children('a').unbind();
        });

        $('.subwrapper').removeAttr('style');
        // -- END RESET

        $('#btn_user').unbind().click(function () {
            $('#user_wrapper').slideToggle();
        });
    }
}

function initEqualHeights() {
    if ($('.equalHeights').length) {
        $('.equalHeights .equals').css('height', '');
        if (window.innerWidth >= 720) {
            var height = 0;
            $('.equalHeights .equals').each(function (i, el) {
                if ($(el).outerHeight() > height) {
                    height = $(el).outerHeight();
                }
            });
            $('.equalHeights .equals').outerHeight(height);
        }
    }
}

function initSearch() {
    if ($('#search').length > 0) {
        input = $('#search');
        form = $('#search').parents('form');
        searchresults = $('#searchresults');
        //uitgebreid = $('#uitgebreid-zoeken');

        input.keyup(function (e) {
            if (e.which !== 13) {
                regex = /^(Postcode: )?([0-9]{4})$/;
                if ((m = regex.exec(input.val())) !== null) {
                    searchresults.fadeOut();
                    input.val('Postcode: ' + m[2]);
                    input.attr('name', 'postcode')
                } else {
                    input.attr('name', 'zoekopdracht')
                    input.val(input.val().replace('Postcode: ', ''));
                    doSearch(input.val());
                }
            }
        });

        input.blur(function () {
            searchresults.fadeOut();
        });

        input.focus(function () {
            doSearch(input.val());
        });

        // form.find('a.more').on('click', function (e) {
        //     e.preventDefault();
        //     $(this).toggleClass('open');
        //     uitgebreid.finish().slideToggle();
        //
        //     if (input.attr('name') == 'zoekopdracht') {
        //         input.attr('name', 'plaats');
        //     } else {
        //         input.attr('name', 'zoekopdracht')
        //     }
        // });

        form.on('submit', function () {
            input.val(input.val().replace('Postcode: ', ''));
        });
    }
}

var currentRequest = null;
function doSearch(value) {

    if (value.length > 3) {

        //if ($('#uitgebreid-zoeken').is(':hidden')) {
        currentRequest = $.ajax({
            dataType: 'json',
            method: "GET",
            url: form.data('ajax'),
            data: {
                'search': input.val()
            },
            beforeSend: function () {
                if (currentRequest != null) {
                    console.log('aborting');
                    currentRequest.abort();
                }
                $('ul.results').hide();
                $('ul.pending').show();
                searchresults.fadeIn();
            },
            success: function (response) {
                $('ul.pending').hide();
                $('ul.results').show();

                $('ul.results li').remove();

                if ($(response).length > 0) {
                    $(response).each(function (i, entry) {
                        if (entry.type === 'city') { // = plaats
                            value = '<a href="/zoekresultaten?plaats=' + entry.reference + '">' + entry.text + '</a>';
                        } else { // = object
                            value = '<a href="/object/' + entry.reference + '">' + entry.text + ' <small>(' + entry.subtext + ')</small></a>';
                        }
                        $('ul.results').append($('<li class="list-group-item"></li>').html(value));
                    });
                } else {
                    $('ul.results').append($('<li class="list-group-item"></li>').html('<em class="text-muted">Geen resultaten gevonden</em>'));
                }
            },
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        //}
    } else {
        searchresults.fadeOut();
    }
}

function initMagnific() {
    $('.magnific').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
}

function initObjectFavorite() {
    $('.btn.favorite').on('click', function () {
        var $this = $(this);
        var $span = $this.find('span');

        $.ajax({
            dataType: 'json',
            method: "POST",
            url: '/object/favorite',
            data: {
                'object_id': $this.data('id')
            },
            success: function (response) {
                if (response == 'added') {
                    $span.removeClass('fa-star-o').addClass('fa-star');
                    $this.removeClass('btn-primary').attr('title', 'Volgend');
                    $this.removeClass('btn-default').addClass('btn-primary');
                } else if (response == 'deleted') {
                    $this.removeClass('btn-primary').addClass('btn-default');
                    $this.removeClass('btn-primary').attr('title', 'Volgen');
                    $span.removeClass('fa-star').addClass('fa-star-o');
                }
            },
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        console.log($span);
    });
}

function initMasonry() {
    $('.grid').masonry({
        // set itemSelector so .grid-sizer is not used in layout
        itemSelector: '.grid-item',
        // use element for option
        columnWidth: '.grid-sizer',
        percentPosition: true
    })

    $('#kenmerkenTab').on('shown.bs.tab', function () {
        $('.grid').masonry({
            // set itemSelector so .grid-sizer is not used in layout
            itemSelector: '.grid-item',
            // use element for option
            columnWidth: '.grid-sizer',
            percentPosition: true
        })
    });
}

function initFaq() {
    if ($('#faq').length > 0) {
        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}